import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { all_route_group } from "./routes";
import { Helmet } from "react-helmet";
import "@ravenpay/raven-bank-ui/src/styles/index.css";
import SuspensePreLoader from "./components/fragments/SuspensePreLoader";
import { handleScrollToTop } from "./utils";
// import "@/scripts/intercom";
import NotFoundPage from "./pages/not-found";
import { RavenToast } from "@ravenpay/raven-bank-ui";
// import CaptchaComponent from "./utils/CaptchaComponent";

function App() {
  const location = useLocation();
  // const [showCaptcha, setShowCaptcha] = useState(false);
  useEffect(() => {
    handleScrollToTop();
  }, [location.pathname]);

  return (
    <div className="App">
      <Helmet>
        <title>Raven || Ecosystem of Finance Tools for Africans </title>
        <meta
          name="description"
          content="Raven allows you to transfer money to other banks for free; Get Loans and up to 15% ROI on your savings plan."
        />
        <meta
          property="og:description"
          content="Raven allows you to transfer money to other banks for free; Get Loans and up to 15% ROI on your savings plan."
        />
        <meta
          name="keywords"
          content="bank, banking, loan, savings, roi, interest, nigeria, africa, fintech,  Speed, fast transactions, corporate account, business account, official account, loan, overdraft, savings, payroll, inventory, invoice, receipts, account management, Fast transfers, speed, fastest bank, digital banking, online banking, savings, high interest savings, fixed savings, target savings, investment, overdraft, Bills Payment, airtime, data, electricity bills payment, cable TV subscription, virtual cards, physical cards, debit cards, virtual dollar cards, virtual naira cards, fast internet (Raven Feather Internet"
        />
        <meta name="page-topic" content="Banking" />
        <meta name="page-type" content="Landing" />
        <meta name="audience" content="Everyone" />
        <meta
          property="og:image"
          content="https://s3.eu-west-2.amazonaws.com/img.creativepool.com/files/candidate/portfolio/full/2022460.jpg"
        />
        <meta property="og:url" content="https://getravenbank.com" />
        <meta name="robots" content="index, follow" />
        <meta
          property="twitter:image:src"
          content="https://s3.eu-west-2.amazonaws.com/img.creativepool.com/files/candidate/portfolio/full/2022460.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="GetRavenBank" />
        <meta name="twitter:site" content="https://www.twitter.com/theravenbank" />
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
        />
      </Helmet>

      <Routes location={location}>
        <Route path="*" element={<NotFoundPage />} />

        {/* auth group start */}
        {all_route_group?.map((route, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <Suspense fallback={<SuspensePreLoader />}>
                <route.element />
              </Suspense>
            }
          />
        ))}
        {/* auth group end */}
      </Routes>
      <RavenToast />
      {/* {showCaptcha && (
        <CaptchaComponent
          onVerify={function (value: string | null): void {
         
          }}
        />
      )} */}
    </div>
  );
}

export default App;
